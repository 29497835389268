<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h3 class="card-label">
        <small>Bakım Detayı</small><br />
        <router-link
          v-if="originalData && originalData.care_id"
          class="w-100 text-subtitle-2"
          :to="{
            name: 'other-definitions.maintenance.edit',
            params: { id: originalData.care_id },
          }"
        >
          Oluşturulan Bakım Tanımına Git
        </router-link>
      </h3>
      <div class="d-block w-100 mb-2">
        <rs-return-to />
      </div>
    </v-card-title>
    <v-card-text v-if="originalData.id">
      <v-form>
        <!-- <p class="font-weight-bold">Not Hatırlatıcısı</p> -->
        <v-row dense>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Hatırlatma Tarihi"
              :value="new Date(originalData.remind_at).toLocaleString()"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Durum"
              :value="originalData.closed_at === null ? 'Açık' : 'Kapalı'"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <v-dialog
              v-if="originalData.closed_at === null"
              v-model="dialog"
              persistent
              max-width="600px"
            >
              <template v-slot:activator="{ on, attrs }">
                <rs-action v-bind="attrs" v-on="on">Kapat</rs-action>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Bakım Hatırlatıcısını Kapat</span>
                </v-card-title>
                <v-form ref="closeForm" @submit.prevent="handleCloseFormSubmit">
                  <v-card-text>
                    <v-container>
                      <p class="mb-0">
                        Bu işlemi yaparak bakım hatırlatıcısını kapatmış
                        olacaksınız.
                      </p>
                      <rs-textarea
                        label="Açıklama"
                        required
                        rows="5"
                        no-resize
                        v-model="closeText"
                        :rules="[rules.required]"
                      />
                      <rs-datepicker
                        label="Kapatma Tarihi"
                        v-model="closedAt"
                        hint="Faaliyet raporunda bu tarih gözükür"
                        :rules="[
                          rules.minOrEqualDate(
                            closedAt,
                            new Date(originalData.remind_at)
                          ),
                          rules.maxOrEqualDate(closedAt, new Date()),
                        ]"
                      />
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                      İptal
                    </v-btn>
                    <v-btn type="submit" color="blue darken-1" text>
                      Kaydet ve Kapat
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>

            <template v-else>
              <p>
                Bu bakım hatırlatıcısı
                <strong>{{
                  new Date(originalData.closed_at).toLocaleString()
                }}</strong>
                zamanında
                <strong>
                  {{
                    originalData.closed_by_user.name
                      ? originalData.closed_by_user.name
                      : "bilinmeyen"
                  }}
                </strong>
                kişisi tarafından kapatıldı.
              </p>
              <p>
                <strong>Kapatma açıklaması:</strong>
                {{ originalData.close_text }}
              </p>
            </template>
          </v-col>
        </v-row>

        <v-divider />

        <h5>Bilgiler</h5>

        <v-row dense>
          <v-col lg="4" cols="6">
            <rs-text-field
              label="Konu"
              :value="originalData.maintenance.subject"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-select
              label="Bakım Türü"
              :items="maintenanceTypes"
              v-model="originalData.maintenance.maintenance_type_id"
              readonly
            />
          </v-col>
          <v-col lg="4" cols="6">
            <rs-select
              label="Bakım Tipi"
              :items="childMaintenanceTypes"
              v-model="originalData.maintenance.child_maintenance_type_id"
              readonly
            />
          </v-col>
          <v-col cols="12">
            <rs-textarea
              :label="$t('labels.description')"
              :value="originalData.maintenance.description"
              rows="5"
              no-resize
              readonly
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { hasForm } from "@/view/mixins";
import { mapGetters } from "vuex";
import ClusterService from "@/core/services/cluster.service";

export default {
  name: "MaintenanceForm",
  mixins: [hasForm],
  props: {
    /**
     * Maintenance Reminder Schedule ID
     * @type {Number}
     */
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["maintenanceTypes"]),
  },
  watch: {
    id(newValue) {
      if (newValue) {
        this.load();
      }
    },
  },
  data() {
    return {
      dialog: false,
      closeText: null,
      closedAt: null,
      childMaintenanceTypes: [],
    };
  },
  mounted() {
    if (this.id) {
      this.$nextTick(() => this.load());
    }
  },
  methods: {
    handleMaintenanceTypeChange() {
      this.childMaintenanceTypes = ClusterService.getMaintenanceTypeChildren(
        this.originalData.maintenance.maintenance_type_id
      );
    },
    load() {
      if (this.isLoading || !this.id) {
        return false;
      }

      this.isLoading = true;

      this.$api
        .get("maintenance-schedules", this.id)
        .then((response) => {
          this.loadData(response);
          this.handleMaintenanceTypeChange();
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleCloseFormSubmit() {
      if (this.isLoading || !this.$refs.closeForm.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.$api
        .post(`maintenance-schedules/${this.id}/close`, {
          close_text: this.closeText,
          closed_at: this.closedAt || undefined,
        })
        .then(() => {
          this.$toast.success(this.$t("saved"));
          this.closeText = null;
          this.dialog = false;

          this.$nextTick(() => {
            this.$router.push({ name: "communication.maintenances.list" });
          });
        })
        .catch((error) => this.handleError(error))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>
